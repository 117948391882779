import { sortBy } from 'lodash'
import veryTask from '../data/components/tubeTaskData.json'

export const getSpecialStatusCase = (status, kind) => {
    let statusReturn
    status = status?.toUpperCase()
    kind = kind?.toUpperCase()
    const specialCase = ['VERIFYCREDITPOLICIES', 'VERIFYBUREAU', 'VERIFYADDRESSWITHGEOCODING',  'VERIFYBANKACCOUNT', 'VERIFYIDENTITYWITHPHONECALL']
    if (specialCase.indexOf(kind) >= 0 && (['NEW', 'NEEDSCORRECTION'].includes(status))) { 
        statusReturn = 'InProgress'
    }

    return statusReturn
}

export const getSpecialVeriAFI = (kind,description) => {
    let statusReturn
    let i=description
    kind = kind?.toUpperCase()
    const specialCase = ['VERIREFEPERSONAL']
    if (specialCase.indexOf(kind) >= 0 && (['VERIREFEPERSONAL'].includes(kind))) { 
        statusReturn = `VeriRefePersonal_${i}`        
    }

    return statusReturn
}

export const getOrderArray = (array, field = 'status', order = { new: 1, needscorrection: 2, inprogress: 3, completed: 4, error: 5 }) => {
    return sortBy(array, element => {
        return order[element[field]?.toLowerCase()];
    })
}

export const getTaskUrl = (task) => {
    task = task.toUpperCase()    
    let rowtask= veryTask.filter(veryTask=> veryTask.taskName=== task)    
    if(rowtask.length>0) return rowtask[0].url
    else return ''
}

export const getTaskActionText = (task, lang = 'es') => {
    task = task.toUpperCase()    
    let rowtask= veryTask.filter(veryTask=> veryTask.taskName=== task)     
    if(rowtask.length>0 ){
        if(lang !== 'es') return rowtask[0].actionTextEn
        else return rowtask[0].actionText
    } 
    else {
        return ''
    }

}