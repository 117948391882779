import Service from '../Service'
import { LandingV4Instance } from './Instance'

/**
* @typedef {Object} TubeData
* @property {String} mobileNumber
* @property {String} email
*/

/**
 * @class
 * @param  {String} creditApplicationId
 */
export class TubeService extends Service {
  constructor(creditApplicationId) {
    super()
    this._creditApplicationId = `${creditApplicationId}`
  }

  /**
   * @param  {String} mobileNumber
   */
  setMobileNumber(mobileNumber) {
    this._mobileNumber = `${mobileNumber}`
  }

  /**
   * @param  {String} email
   */
  setEmail(email) {
    this._email = `${email}`
  }

  /**
   * @returns {TubeData}
   */
  get search() {
    return {
      mobileNumber: this._mobileNumber,
      email: this._email
    }
  }

  /**
   * @returns {String}
   */
  get id() {
    return this._creditApplicationId
  }


  /**
   * @returns {Promise<import('../Service').ServiceResponse|import('../Service').ServiceError>}
   */
  sendSearch() {
    const self = this
    return this._requestWrapper(LandingV4Instance({
      method: 'GET',
      url: `/tube/search`,
      params: self.search
    }))
  }


  /**
   * @returns {Promise<import('../Service').ServiceResponse|import('../Service').ServiceError>}
   */
  send() {
    const self = this
    return this._requestWrapper(LandingV4Instance({
      method: 'GET',
      url: `/tube/${self.id}`,
    }))
  }

  /**
   * @returns {Promise<import('../Service').ServiceResponse|import('../Service').ServiceError>}
   */
  sendLang(locale) {
    const self = this
    return this._requestWrapper(LandingV4Instance({
      method: 'GET',
      url: `/tube/${self.id}/${locale}`,
    }))
  }
}
